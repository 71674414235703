<template>
  <div class="page-tag-view">
    <div class="header">
      <div class="section">
        <p class="title-text text">You are searching for:</p>
        <p class="search-term">{{ searchTerm }}</p>
      </div>
      <div class="section">
        <p class="text">search by:</p>
        <div
          class="checkbox-container"
          v-for="key in Object.keys(searchTypes)"
          :key="key">
          <label :for="key" class="search-type">{{ key }}</label>
          <input
            class="checkbox"
            type="checkbox"
            name="key"
            v-model="searchTypes[key]"
            @change="isShowingButton = true" />
        </div>
      </div>
      <div class="button-container">
        <button
          class="button button-primary"
          v-if="isShowingButton"
          @click="onChangeFilter">
          refine search
        </button>
      </div>
      <div class="search-bar-box">
        <search-bar></search-bar>
      </div>
    </div>
    <posts-container
      v-if="posts.length > 0"
      :posts="posts"
      name="search"
      @changeFilters="onChangeFilter"></posts-container>
    <div v-else class="no-posts-found-box">
      <span class="no-posts-found-text"
        >sorry, nothing found with '{{ this.query }}'</span
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PostsContainer from "../components/posts/PostsLargeContainer.vue";
import SearchBar from "../components/SearchBar.vue";
export default {
  name: "TagView",
  components: {
    PostsContainer,
    SearchBar,
  },
  computed: {
    searchTerm() {
      return this.query;
    },
  },
  data() {
    return {
      lastPostRecieved: 0,
      sorting: "popular",
      time: "recent",
      filter: "none",
      posts: [],
      query: "",
      searchTypes: {
        title: true,
        description: true,
        tags: false,
        user: false,
      },
      isShowingButton: false,
    };
  },
  methods: {
    getNextFewPosts(query = this.$route.query.query) {
      this.$store.commit("setLoading", true);
      console.log(query);
      this.query = query;
      axios
        .get("/api/v1/posts/search/", {
          params: {
            from: this.lastPostRecieved,
            time: this.time,
            filter: this.filter,
            sorting: this.sorting,
            query: this.query,
            search_title: this.searchTypes.title,
            search_description: this.searchTypes.description,
            search_tags: this.searchTypes.tags,
            search_user: this.searchTypes.user,
          },
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("setLoading", false);
          document.title = "search: " + this.query + " | icandoathing";

          this.posts.splice(0);
          res.data.forEach((post) => {
            this.posts.push(post);
            this.lastPostRecieved++;
          });
        })
        .catch((err) => {
          this.$store.commit("setLoading", false);
          console.log(err);
        });
    },
    onChangeFilter(data) {
      this.isShowingButton = false;
      this.sorting = data.sorting || "popular";
      this.time = data.time || "recent";
      this.filter = data.filter || "none";
      this.lastPostRecieved = 0;
      this.posts.splice(0);
      this.getNextFewPosts();
    },
  },
  mounted() {
    this.$store.dispatch("ping");
    this.getNextFewPosts();
  },
  beforeRouteUpdate(to, from, next) {
    this.posts.splice(0);
    this.lastPostRecieved = 0;
    requestAnimationFrame(() => {
      this.getNextFewPosts(to.query.query);
      next();
    });
  },
};
</script>

<style lang="scss" scoped>
.page-tag-view {
  width: 100%;
}
.header {
  width: 100%;
  display: flex;
  padding-left: 1rem;
  margin: $item-margin;
  font-size: 1.2rem;
  background-color: $grey-300;
  border-radius: $border-radius;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: $item-margin;
}
.section {
  width: fit-content;
  max-width: 25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
}
.text {
  font-weight: 300;
  margin: 0;
}
.search-term {
  color: $primary;
  font-weight: 600;
  margin: auto 1rem;
}
.search-type {
  color: $primary-400;
  margin-left: 1rem;
  margin-right: 0.2rem;
  cursor: pointer;
}
.search-bar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  min-width: 20rem;
}
.checkbox-container {
  display: flex;
  align-items: center;
}
.button-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}
.no-posts-found-box {
  height: 7rem;
  margin: $item-margin;
  border: solid $grey-300 3px;
  border-radius: $border-radius;
  margin-top: 2rem;
  margin-bottom: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-posts-found-text {
  font-size: 2rem;
  font-weight: 200;
  color: $grey-500;
}
@media screen and (max-width: $switch-width) {
  .section {
    max-width: none;
  }
  .header {
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .spacer {
    display: none;
  }
  .search-bar-box {
    width: fit-content;
    margin-bottom: 0.1rem;
  }
  .title-text {
    margin: 0.1rem 0;
  }
  .checkbox-container {
    margin: 0.1rem 0;
  }
  .text {
    margin: 0.1rem 0;
  }
}
</style>
